// extracted by mini-css-extract-plugin
export var mainContainer = "extendedPlastika-module--mainContainer--3YscT";
export var container = "extendedPlastika-module--container--WW3xz";
export var text = "extendedPlastika-module--text--2D5cF";
export var image = "extendedPlastika-module--image--33bXV";
export var body = "extendedPlastika-module--body--aP2We";
export var title = "extendedPlastika-module--title--TvWeD";
export var pricelist = "extendedPlastika-module--pricelist--2hfOm";
export var priceTitle = "extendedPlastika-module--priceTitle--4y862";
export var price = "extendedPlastika-module--price--2eoRs";
export var logo = "extendedPlastika-module--logo--17Iet";
export var gallery = "extendedPlastika-module--gallery--RgbpG";
export var icon = "extendedPlastika-module--icon--DqGVF";
export var services = "extendedPlastika-module--services--1Upqx";
export var reverse = "extendedPlastika-module--reverse--2IxR7";
export var noImage = "extendedPlastika-module--noImage--m7G-0";
export var noImageReverse = "extendedPlastika-module--noImageReverse--3QPpU";
export var container1 = "extendedPlastika-module--container1--30Ts9";
export var overflowHidden = "extendedPlastika-module--overflowHidden--JHUwp";
export var maxWidth1 = "extendedPlastika-module--maxWidth1--3-mGS";
export var maxWidth2 = "extendedPlastika-module--maxWidth2--ny5Lh";
export var procedureTitle = "extendedPlastika-module--procedureTitle--2SLvi";
export var priceContainer = "extendedPlastika-module--priceContainer--106U3";
export var mainTitle = "extendedPlastika-module--mainTitle--1L2Ae";
export var indication = "extendedPlastika-module--indication--3giAR";
export var indicationTitle = "extendedPlastika-module--indicationTitle--2sjgY";
export var galleryImage = "extendedPlastika-module--galleryImage--3jypy";
export var faqs = "extendedPlastika-module--faqs--2W6Eo";