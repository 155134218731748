import React from "react";
import { StaticQuery, graphql } from "gatsby";

import ExtendedPlastika from "./extendedPlastika";

const detailsQuery = graphql`
  query ExtendedPlastikaQuery {
    sanityExtendedPlastika {
      procedureExtentions {
        image {
          ...SanityImage
          alt
        }
        letter
        procedure {
          _id
        }
        orderLink {
          href
        }
        faqs {
          description
          title
        }
      }
      procedureTitle
      _rawSelectedImage(resolveReferences: { maxDepth: 10 })
    }
  }
`;

function ExtendedPlastikaContainer(serviceProps) {
  return (
    <StaticQuery
      query={detailsQuery}
      render={({ sanityExtendedPlastika }) => {
        return <ExtendedPlastika {...serviceProps} extendedData={sanityExtendedPlastika} />;
      }}
    />
  );
}

export default ExtendedPlastikaContainer;
