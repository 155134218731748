import React from "react";
import { cn } from "../lib/helpers";
import { Link } from "gatsby";

import GalleryMain from "./galleryMain";

import * as styles from "./promoGalerie.module.css";
import * as commonStyles from "../styles/common.module.css";

function PromoMagazin({ selectedImage, hideTitle }) {
  console.log("selectedImage", selectedImage);

  return (
    <div className={cn(styles.container1, hideTitle && styles.hiddenTitle)}>
      {!hideTitle && (
        <h2 className={cn(styles.mag, commonStyles.colorAccent, commonStyles.fontButlerMedium)}>
          Galerie
        </h2>
      )}
      <div className={styles.gallery}>
        <GalleryMain data={[selectedImage]} promo />
      </div>

      <Link to={"/galerie"}>
        <div className={cn(styles.more, commonStyles.fontReleway, commonStyles.colorAccent)}>
          Více fotek
        </div>
      </Link>
    </div>
  );
}

export default PromoMagazin;
