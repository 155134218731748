import React, { useState } from "react";
import { cn } from "../lib/helpers";
import { Link } from "gatsby";

import { GatsbyImage } from "gatsby-plugin-image";
import { getGatsbyImageData } from "gatsby-source-sanity";
import clientConfig from "../../client-config";

import PortableText from "./portableText";
import FadeInSection from "./FadeIn";
import InfiniteLoop from "./infiniteLoop";
import Icon from "./icon/icon";
import LetterText from "./letterText";
import BrushImage from "./brushImage";
import slugify from "../slugify";
import FaqItem from "./FAQ/FaqItem";

import PromoGalerie from "./promoGalerie";

import * as styles from "./extendedTCM.module.css";
import * as commonStyles from "../styles/common.module.css";
import * as serviceStyles from "./services.module.css";

function ExtendedTCM(props) {
  const { categories, mainImage, procedures, title, _rawBody, _rawPricelist, extendedData } = props;

  const {
    topGallery = [],
    procedureExtentions,
    procedureTitle,
    indicationTitle,
    faqTitle,
    indications,
    faqs,
    _rawSelectedImage,
  } = extendedData;
  console.log("_rawSelectedImage", _rawSelectedImage);

  const [iconActive, setActive] = useState(false);
  setTimeout(() => {
    setActive(true);
  }, 200);

  const mergedProcedures = procedures
    .map((prc) => {
      const { procedure = {} } = prc;
      const extention = procedureExtentions.find((ext) => ext.procedure._id === procedure._id);

      if (extention) return { ...procedure, ...extention };
    })
    .sort((a, b) => (a.image ? (b.image ? 0 : -1) : 1));

  const mainImageData = getGatsbyImageData(mainImage, {}, clientConfig.sanity);
  return (
    <>
      <FadeInSection>
        <div className={styles.container}>
          <div className={styles.image}>
            <div className={styles.icon}>
              <Icon type={"akupunktura"} active={iconActive} width={"160px"} />
            </div>
            {mainImage && <GatsbyImage image={mainImageData} />}
          </div>

          <div className={styles.text}>
            <h1
              className={cn(styles.title, commonStyles.colorAccent, commonStyles.fontButlerMedium)}
            >
              {title}
            </h1>
            <div className={cn(styles.body, commonStyles.colorDarkGray, commonStyles.fontButler)}>
              <PortableText blocks={_rawBody} />
            </div>
          </div>
        </div>
      </FadeInSection>
      <FadeInSection>
        {topGallery.length > 0 && (
          <div className={styles.gallery}>
            <InfiniteLoop speed={topGallery.length * 8} direction="left">
              {topGallery.map((image, index) => {
                const imageData = getGatsbyImageData(image, { height: 600 }, clientConfig.sanity);

                // return (
                //   <img
                //     src={imageData.images.fallback.src}
                //     key={`gallery_key_${index}`}
                //     alt={image.alt}
                //     className={styles.galleryImage}
                //   />
                // );
                return (
                  <GatsbyImage
                    objectFit="contain"
                    key={`gallery_key_${index}`}
                    image={imageData}
                    alt={image.alt}
                    loading="eager"
                    critical={true}
                  />
                );
              })}
            </InfiniteLoop>
          </div>
        )}
      </FadeInSection>
      <FadeInSection>
        <h2
          dangerouslySetInnerHTML={{ __html: procedureTitle }}
          className={cn(styles.mainTitle, commonStyles.colorAccent, commonStyles.fontButlerMedium)}
        ></h2>
      </FadeInSection>

      {/* *** Services start *** */}
      <div className={cn(styles.container1, styles.overflowHidden)}>
        {mergedProcedures.length > 0 &&
          mergedProcedures.map((procedure, index) => {
            const odd = !!(index % 2);
            return (
              <div
                className={cn(
                  serviceStyles.container2,
                  procedure.image ? styles.maxWidth1 : styles.maxWidth2,
                  odd && styles.reverse
                )}
              >
                <FadeInSection>
                  <div
                    className={cn(
                      serviceStyles.container3,
                      !procedure.image ? (odd ? styles.noImageReverse : styles.noImage) : ""
                    )}
                  >
                    <div className={styles.container1}>
                      <LetterText
                        title={
                          <h2
                            className={cn(
                              styles.procedureTitle,
                              commonStyles.colorDarkGray,
                              commonStyles.fontButlerMedium
                            )}
                          >
                            {procedure.title}
                          </h2>
                        }
                        blocks={procedure._rawBody}
                        letter={procedure.letter}
                      />
                      {procedure.pricelist.length > 0 ? (
                        <div className={cn(styles.services)}>
                          {procedure.pricelist.map((price, indexP) => (
                            <div key={`price_key_${indexP}`}>
                              <div
                                className={cn(
                                  styles.priceTitle,
                                  commonStyles.colorDarkGray,
                                  commonStyles.fontButlerMedium
                                )}
                              >
                                {price.title}
                              </div>
                              <div
                                className={cn(
                                  styles.price,
                                  commonStyles.colorDarkGray,
                                  commonStyles.fontButler
                                )}
                              >
                                {price.price}
                              </div>
                            </div>
                          ))}
                          {procedure?.orderLink?.href && (
                            <a
                              className={cn(
                                styles.priceTitle,
                                commonStyles.colorAccent,
                                commonStyles.fontButlerMedium
                              )}
                              href={`${procedure.orderLink.href}?${encodeURI(procedure.title)}`}
                            >
                              {procedure.orderLink.href.includes("shop") ? "Koupit" : "Objednat"}
                            </a>
                          )}
                        </div>
                      ) : (
                        <div
                          className={cn(
                            styles.priceTitle,
                            styles.priceContainer,
                            commonStyles.colorDarkGray,
                            commonStyles.fontButlerMedium
                          )}
                        >
                          {procedure.price}
                          {procedure?.orderLink?.href && (
                            <a
                              className={commonStyles.colorAccent}
                              href={`${procedure.orderLink.href}?${encodeURI(procedure.title)}`}
                            >
                              {procedure.orderLink.href.includes("shop") ? "Koupit" : "Objednat"}
                            </a>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </FadeInSection>
                {procedure.image && (
                  <FadeInSection>
                    <div className={serviceStyles.image}>
                      <BrushImage image={procedure.image} />
                    </div>
                  </FadeInSection>
                )}
              </div>
            );
          })}
      </div>
      <FadeInSection>
        <h2
          className={cn(styles.mainTitle, commonStyles.colorAccent, commonStyles.fontButlerMedium)}
        >
          Před a po
        </h2>
        <PromoGalerie selectedImage={_rawSelectedImage} hideTitle />
      </FadeInSection>
      {/* *** Services end *** */}
      <FadeInSection>
        <h2
          dangerouslySetInnerHTML={{ __html: indicationTitle }}
          className={cn(styles.mainTitle, commonStyles.colorAccent, commonStyles.fontButlerMedium)}
        ></h2>
        {indications.length > 0 &&
          indications.map((indication, index) => {
            return (
              <div key={`indication_${index}`} className={styles.indication}>
                <h3
                  className={cn(
                    styles.indicationTitle,
                    commonStyles.colorDarkGray,
                    commonStyles.fontButlerMedium
                  )}
                >
                  {indication.title}
                </h3>
                <div
                  className={cn(
                    commonStyles.tags,
                    commonStyles.fontReleway,
                    commonStyles.colorDarkGray
                  )}
                >
                  {indication.categories.map((tag, indexT) => {
                    const { title } = tag;
                    return (
                      <Link to={`/vyhledavani/${slugify(title)}`}>
                        <span key={`procedure_tag_${indexT}`}>
                          {title}
                          {indexT !== indication.categories.length - 1 && " — "}
                        </span>
                      </Link>
                    );
                  })}
                </div>
              </div>
            );
          })}
      </FadeInSection>
      <FadeInSection>
        <h2
          dangerouslySetInnerHTML={{ __html: faqTitle }}
          className={cn(styles.mainTitle, commonStyles.colorAccent, commonStyles.fontButlerMedium)}
        ></h2>
        {faqs.length > 0 &&
          faqs.map((faq, index) => (
            <FaqItem key={`faq_${index}`} question={faq.title} answer={faq.description} />
          ))}
      </FadeInSection>
    </>
  );
}

export default ExtendedTCM;
