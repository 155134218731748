import React from "react";
import { StaticQuery, graphql } from "gatsby";

import ExtendedTCM from "./extendedTCM";

const detailsQuery = graphql`
  query ExtendedTCMQuery {
    sanityExtendedTcm {
      topGallery {
        ...SanityImage
        alt
      }
      procedureExtentions {
        image {
          ...SanityImage
          alt
        }
        letter
        procedure {
          _id
        }
        orderLink {
          href
        }
      }
      faqTitle
      indicationTitle
      procedureTitle
      faqs {
        description
        title
      }
      indications {
        title
        categories {
          _id
          title
        }
      }
      _rawSelectedImage(resolveReferences: { maxDepth: 10 })
    }
  }
`;

function ExtendedTCMContainer(serviceProps) {
  return (
    <StaticQuery
      query={detailsQuery}
      render={({ sanityExtendedTcm }) => {
        return <ExtendedTCM {...serviceProps} extendedData={sanityExtendedTcm} />;
      }}
    />
  );
}

export default ExtendedTCMContainer;
