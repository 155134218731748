import React from "react";

import { cn } from "../lib/helpers";

import PortableText from "./portableText";

import * as styles from "./letterText.module.css";
import * as commonStyles from "../styles/common.module.css";

const LetterText = ({ blocks, letter = "", title, subtitle, small }) => {
  const isCh = letter === "Ch";

  return (
    <div
      className={cn(
        styles.container,
        commonStyles.colorDarkGray,
        commonStyles.fontButler,
        small && styles.small
      )}
    >
      <div
        className={cn(
          styles.letter,
          isCh && styles.ch,
          commonStyles.colorAccent,
          commonStyles.fontButlerMedium,
          small && styles.small
        )}
      >
        {letter}
      </div>
      <div>
        {title && (
          <h2
            className={cn(styles.title, commonStyles.colorDarkGray, commonStyles.fontButlerMedium)}
          >
            {title}
          </h2>
        )}
        {subtitle && (
          <p className={cn(styles.subtitle, commonStyles.colorDarkGray, commonStyles.fontReleway)}>
            {subtitle}
          </p>
        )}
        <PortableText blocks={blocks} />
      </div>
    </div>
  );
};

export default LetterText;
