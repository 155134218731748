import React from "react";
import { graphql } from "gatsby";

import Layout from "../containers/layout";
import Container from "../components/container";
import GraphQLErrorList from "../components/graphql-error-list";
import SEO from "../components/seo";
import PageContainer from "../components/pageContainer";
import ServiceMain from "../components/serviceMain";
import Procedures from "../components/procedures";
import ExtendedTCMContainer from "../components/extendedTCMContainer";
import ExtendedPlastikaContainer from "../components/extendedPlastikaContainer";

import { toPlainText } from "../lib/helpers";

export const query = graphql`
  query ServiceTemplateQuery($id: String!) {
    allServices: allSanityService(sort: { fields: title }) {
      nodes {
        title
        disabled
        slug {
          current
        }
      }
    }
    magazinPreview: allSanityPost(sort: { fields: publishedAt, order: DESC }, limit: 6) {
      nodes {
        title
        slug {
          current
        }
      }
    }
    tags: allSanityCategory(sort: { fields: title }) {
      nodes {
        title
        _id
      }
    }
    service: sanityService(id: { eq: $id }) {
      id
      _rawExtendedData
      categories {
        _id
        title
      }
      mainImage {
        ...SanityImage
        alt
      }
      title
      slug {
        current
      }
      procedures {
        procedure {
          _id
          title
          price
          pricelist {
            price
            title
          }
          _rawBody
          categories {
            title
            _id
          }
        }
      }
      _rawExcerpt(resolveReferences: { maxDepth: 5 })
      _rawBody(resolveReferences: { maxDepth: 5 })
      _rawPricelist
    }
  }
`;

const ServiceTemplate = (props) => {
  const { data, errors } = props;
  const service = data && data.service;
  const allServices = data && data.allServices.nodes;
  const magazinPreview = data && data?.magazinPreview?.nodes;
  const tags = data && data?.tags?.nodes;
  const extendedData = service && service?._rawExtendedData?._ref;
  return (
    <Layout services={allServices} magazin={magazinPreview} tags={tags} logoAnimation={false}>
      {errors && <SEO title="GraphQL Error" />}
      {service && (
        <SEO
          title={service.title || "Untitled"}
          description={toPlainText(service._rawExcerpt)}
          image={service.mainImage}
        />
      )}
      {errors && (
        <Container>
          <GraphQLErrorList errors={errors} />
        </Container>
      )}

      {extendedData && (
        <PageContainer>
          {extendedData === "extendedTCM" && <ExtendedTCMContainer {...service} />}
          {extendedData === "extendedPlastika" && <ExtendedPlastikaContainer {...service} />}
        </PageContainer>
      )}

      {!extendedData && (
        <PageContainer>
          <ServiceMain {...service} />
          <Procedures {...service} />
        </PageContainer>
      )}
    </Layout>
  );
};

export default ServiceTemplate;
